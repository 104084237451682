<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4">
        <SearchBar
          ref="searchBar"
          @search="onSearch($event)"
          searchLabel="Search by project name..."
        />
      </v-col>
      <v-col class="grow" align="right">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              class="msaBlue white--text"
              v-blur
              v-on="on"
              @click="goToDetail(defaultProjectProps)"
              data-testid="add-project"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
          Add Project
        </v-tooltip>
      </v-col>
      <v-col class="shrink">
        <WAProjectsFilter
          :filters="filters"
          ref="filters"
          @applyFilters="applyFilters($event)"
        />
      </v-col>
      <v-col class="shrink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip, attrs }">
            <v-btn
              color="msaBlue"
              v-bind="attrs"
              v-on="{ ...tooltip }"
              v-blur
              class="white--text"
              @click="gotoImport"
            >
              <v-icon>mdi-plus-box-multiple-outline</v-icon>
            </v-btn>
          </template>
          Mass Import
        </v-tooltip>
      </v-col>
      <v-col class="shrink">
        <ExportProjects :filters="filters" :options="options" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text">
            Projects
            <v-badge
              color="msaBlue"
              bordered
              :content="itemsCount"
              :value="itemsCount"
              offset-y="10"
              class="ml-4"
            />
          </v-card-title>
          <v-data-table
            :headers="headers"
            :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
            :items="items"
            :server-items-length="itemsCount"
            :options.sync="options"
            no-data-text="No results found"
          >
            <template v-slot:[`item.name`]="{ item }">
              <div
                class="msaBlue--text"
                :style="{
                  cursor: 'pointer',
                  minWidth: '300px',
                  overflowWrap: 'anywhere',
                }"
                @click="goToDetail(item)"
              >
                {{ item.name }}
              </div>
            </template>
            <template v-slot:[`item.startDate`]="{ item }">
              {{ item.startDate | filterAsLocalDate }}
            </template>
            <template v-slot:[`item.endDate`]="{ item }">
              {{ item.endDate | filterAsLocalDate }}
            </template>
            <template v-slot:[`item.formsCount`]="{ item }">
              <span
                class="msaBlue--text"
                style="cursor: pointer"
                @click="goToForms(item.id)"
              >
                {{ item.formsCount }}
              </span>
            </template>
            <template v-slot:[`item.documentsCount`]="{ item }">
              <span
                class="msaBlue--text"
                style="cursor: pointer"
                @click="goToAssignedDocuments(item.id)"
              >
                {{ item.documentsCount }}
              </span>
            </template>
            <template v-slot:[`item.usersCount`]="{ item }">
              <span
                class="msaBlue--text"
                :style="{ cursor: 'pointer' }"
                @click="goToDetail(item)"
              >
                {{ item.usersCount }}
              </span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-blur
                    text
                    small
                    v-on="on"
                    @click="goToDetail(item)"
                    :data-testid="`edit-project-${item.name}`"
                  >
                    <v-icon color="msaBlue"> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                Edit
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    text
                    v-on="on"
                    @click="toggleProjectStatus(item)"
                    :loading="item.btnLoading"
                  >
                    <v-icon :color="item.status ? 'green' : 'orange'">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ item.status ? 'Click to archive' : 'Click to activate' }}
                </span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue';
import ExportProjects from '@/components/WAProjects/ExportProjects.vue';
import WAProjectsFilter from '@/components/Filters/WAProjectsFilter.vue';
export default {
  name: 'CompanyProjectsPage',
  components: {
    SearchBar,
    ExportProjects,
    WAProjectsFilter,
  },
  filters: {
    truncate(text, length = 75) {
      if (text.length <= length) {
        return text;
      }
      return text.substring(0, length) + '...';
    },
  },
  data() {
    return {
      defaultProjectProps: {
        id: -1,
        name: '',
        description: '',
        address: '',
        startFrom: '',
        startTo: '',
        endFrom: '',
        endTo: '',
        status: 1,
      },
      items: [],
      itemsCount: 0,
      filters: {
        ...this.$constants.PROJECTS_DEFAULT_FILTERS,
      },
      options: {
        sortBy: ['name'],
        sortDesc: [false],
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      },
      headers: [
        {
          text: 'Name',
          value: 'name',
          align: 'start',
          class: 'headerTextGrey--text lightGrey',
        },
        {
          text: 'Start Date',
          value: 'startDate',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '110px',
        },
        {
          text: 'End Date',
          value: 'endDate',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '110px',
        },
        {
          text: 'Forms',
          value: 'formsCount',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '90px',
        },
        {
          text: 'Assigned Documents',
          value: 'documentsCount',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '165px',
        },
        {
          text: 'Users',
          value: 'usersCount',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '85px',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          sortable: false,
          width: '140px',
        },
      ],
    };
  },
  watch: {
    options: {
      handler(oldVal, newVal) {
        const changed = Object.keys(newVal).some(
          (key) => newVal[key].toString() != oldVal[key].toString(),
        );

        if (changed) {
          this.updateUrl();
        }
      },
      deep: true,
    },
  },
  methods: {
    gotoImport() {
      this.$router.push({ name: 'CompanyProjectsImport' });
    },
    getItems(showLoaderText) {
      const params = {
        filters: this.filters,
        options: this.options,
      };

      if (showLoaderText) {
        params.loaderText = 'Loading...';
      }

      const url = 'get-projects-by-company?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          this.items = response.data.items;
          this.itemsCount = response.data.count;
        })
        .catch((error) => error);
    },
    applyFilters(filters) {
      this.filters = { ...filters };
      this.options.page = 1;
      this.updateUrl();
    },
    goToDetail(project) {
      this.$store.commit(
        'updateSelectedProject',
        JSON.parse(JSON.stringify(project)),
      );
      this.$router.push({
        name: 'ProjectDetails',
        params: { id: project.id },
      });
    },
    getRouteQueries() {
      const query = this.$route.query;
      this.filters = this.$helpers.deserializeFilters(query, this.filters);
      this.options = this.$helpers.deserializeOptions(query, 'name', false);

      this.getItems(true);
    },
    updateUrl() {
      let params = this.$helpers.serializeFilter(
        this.filters,
        this.$constants.PROJECTS_DEFAULT_FILTERS,
      );
      const options = this.$helpers.serializeOptions(
        this.options,
        'name',
        false,
      );
      params = { ...params, ...options };

      this.$router
        .push({ name: this.$route.name, query: params })
        .catch(() => {});
    },
    onSearch(event) {
      this.filters.name = event;
      this.options.page = 1;
      this.updateUrl();
    },
    toggleProjectStatus(project) {
      project.btnLoading = true;
      const params = {
        id: project.id,
      };
      const url = project.status
        ? 'delete-project?format=json'
        : 'restore-project?format=json';
      this.$axios
        .post(url, params)
        .then(() => {
          const index = this.items.findIndex((i) => i.id == project.id);
          this.items.splice(index, 1);
          // for better animation effect
          setTimeout(() => {
            this.getItems();
          }, 100);
        })
        .catch((error) => {
          project.btnLoading = false;
          this.$forceUpdate();
          return error;
        });
    },
    goToForms(projectId) {
      this.$router.push({
        name: 'CompanyForms',
        query: {
          projectIds: [projectId].toString(),
          createdDateIntervalId: this.$constants.FILTER_INTERVALS.ALL_TIME,
        },
      });
    },
    goToAssignedDocuments(contactId) {
      this.$router.push({
        name: 'CompanyAssignedDocuments',
        query: { projectIds: [contactId].toString() },
      });
    },
  },
  mounted() {
    this.getRouteQueries();
    this.$nextTick(() => {
      if (this.filters.name != '') {
        this.$refs.searchBar.search = this.filters.name;
      }
      this.$refs.filters.setFilters();
    });
  },
};
</script>

<style></style>

<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            :color="filterApplied ? 'orange' : 'msaBlue'"
            dark
            v-blur
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            data-testid="filterButton"
          >
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        Filter
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row>
          <v-col class="grow"> Filters </v-col>
          <v-col class="shrink">
            <v-btn
              icon
              dark
              @click="
                dialog = false;
                setFilters();
              "
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row dense>
          <v-col cols="12"> Name </v-col>
          <v-col>
            <v-text-field
              v-model="thisFilters.name"
              outlined
              hide-details
              placeholder="Enter a name"
              dense
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12"> Address </v-col>
          <v-col>
            <v-text-field
              v-model="thisFilters.address"
              outlined
              hide-details
              placeholder="Enter a address"
              dense
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="12"> Start From </v-col>
          <v-col cols="12" md="6">
            <v-menu
              v-model="startFromMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="thisFilters.startFrom"
                  label="From"
                  readonly
                  outlined
                  dense
                  clearable
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="thisFilters.startFrom = ''"
                  data-testid="startFrom"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="thisFilters.startFrom"
                no-title
                @input="startFromMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6">
            <v-menu
              v-model="startToMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="thisFilters.startTo"
                  label="To"
                  outlined
                  dense
                  clearable
                  hide-details
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="thisFilters.startTo = ''"
                  data-testid="startTo"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="thisFilters.startTo"
                no-title
                :min="startToMin"
                @input="startToMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="12"> End Date </v-col>
          <v-col cols="12" md="6">
            <v-menu
              v-model="endFromMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="thisFilters.endFrom"
                  label="From"
                  readonly
                  outlined
                  dense
                  clearable
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="thisFilters.endFrom = ''"
                  data-testid="endFrom"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="thisFilters.endFrom"
                no-title
                @input="endFromMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6">
            <v-menu
              v-model="endToMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="thisFilters.endTo"
                  label="To"
                  outlined
                  dense
                  clearable
                  hide-details
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="thisFilters.endTo = ''"
                  data-testid="endTo"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="thisFilters.endTo"
                no-title
                :min="endToMin"
                @input="endToMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12"> Status </v-col>
          <v-col>
            <v-btn-toggle
              v-model="thisFilters.status"
              mandatory
              dense
              color="msaBlue"
              style="width: 100%"
            >
              <v-btn
                style="width: 33%"
                class="text-capitalize"
                :value="1"
                data-testid="status-active"
              >
                Active
              </v-btn>
              <v-btn
                style="width: 33%"
                class="text-capitalize"
                :value="0"
                data-testid="status-archived"
              >
                Archived
              </v-btn>
              <v-btn
                style="width: 34%"
                class="text-capitalize"
                :value="2"
                data-testid="status-all"
              >
                All
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="clear()"> clear </v-btn>
        <v-btn class="msaBlue white--text" @click="apply()"> apply </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'WAProjectsFilter',
  props: {
    filters: {
      type: Object,
    },
  },
  computed: {
    filterApplied() {
      return (
        JSON.stringify(this.thisFilters) !=
        JSON.stringify(this.$constants.PROJECTS_DEFAULT_FILTERS)
      );
    },
    startToMin() {
      return this.thisFilters.startFrom == '' ||
        this.thisFilters.startFrom == null
        ? undefined
        : this.thisFilters.startFrom;
    },
    endToMin() {
      return this.thisFilters.endFrom == '' || this.thisFilters.endFrom == null
        ? undefined
        : this.thisFilters.endFrom;
    },
  },
  data() {
    return {
      dialog: false,
      thisFilters: {
        ...this.$constants.PROJECTS_DEFAULT_FILTERS,
      },
      startFromMenu: false,
      startToMenu: false,
      endFromMenu: false,
      endToMenu: false,
    };
  },
  methods: {
    clear() {
      this.thisFilters = {
        ...this.$constants.PROJECTS_DEFAULT_FILTERS,
      };
      this.apply();
    },
    apply() {
      this.fixNulls();
      this.$emit('applyFilters', this.thisFilters);
      this.dialog = false;
    },
    fixNulls() {
      if (this.thisFilters.name == null) {
        this.thisFilters.name = '';
      }

      if (this.thisFilters.address == null) {
        this.thisFilters.address = '';
      }

      if (this.thisFilters.startFrom == null) {
        this.thisFilters.startFrom = '';
      }

      if (this.thisFilters.startTo == null) {
        this.thisFilters.startTo = '';
      }

      if (this.thisFilters.endFrom == null) {
        this.thisFilters.endFrom = '';
      }

      if (this.thisFilters.endTo == null) {
        this.thisFilters.endTo = '';
      }
    },
    setFilters() {
      this.thisFilters = { ...this.filters };
    },
  },
};
</script>

<style></style>
